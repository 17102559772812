@import "../node_modules/highlight.js/styles/darcula.css";
@import "assets/styles/icomoon.css";

$off-white: #bababa;
$dark-background: #2B2B2B;
$dark-orange: #783c13;
$orange: #cb7832;
$yellow: #e0c46c;
$green: #a6e22e;
$blue: #6896ba;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: $off-white;
  color: $dark-background;
}

#root {
  overflow-y: hidden;
}

.page-wrapper {
  position: relative;
}

.switch-wrapper {
  position: relative;
  height: 100vh;
  max-height: calc(100vh - 4rem);
  overflow-y: scroll;

  > div {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 4rem);
  }
}

header {
  background-color: $dark-background;
  height: 4rem;

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $orange;
    max-width: 640px;
    margin: auto;
    height: 100%;

    .page-title-container {
      padding-left: 1rem;

      .page-title {
        font-size: 1.2rem;
      }

      .page-subtitle {
        display: none;
        font-size: 0.8rem;
        color: $off-white;
      }
    }

    .nav-main {
      display: flex;
      text-align: center;
      padding-right: 1rem;

      .nav-link {
        width: 5rem;
        padding: 1rem 0;
        text-decoration: none;
        color: $orange;

        &:visited {
          color: $orange;
        }

        &:hover {
          color: $yellow;
          border-bottom: 2px solid $yellow;
        }

        &:active {
          color: #cbac73;
          border-bottom: 2px solid #cbac73;
        }

        &.active {
          color: $off-white;
          border-bottom: 2px solid $off-white;
        }
      }
    }
  }
}

main {
  max-width: 640px;
  margin: auto;
  padding: 1rem 1rem 4rem 1rem;

  section:first-of-type {
    .section-title {
      margin: 0 0 1rem 0;
    }
  }

  section {
    .section-title {
      border-bottom: 2px solid $dark-background;
      margin: 1rem 0;
    }

    .inline-link {
      color: $dark-orange;
      font-weight: bold;

      &:visited {
        color: $dark-orange;
      }

      &:hover {
        color: $orange;
      }
    }

    .inline-contact-link {
      color: $dark-orange;
      font-weight: bold;

      &:visited {
        color: $dark-orange;
      }

      &:hover {
        color: $orange
      }
    }

    .subtitle {
      margin-bottom: 1rem;
      border-bottom: 1px solid $dark-background;
    }
  }

  .skills-breakdown {
    .skills-container {
      margin: 20px 0;
    }

    .skill {
      overflow: hidden;
      max-height: 2rem;
      min-height: 2rem;
      transition: all 0.5s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: $yellow;
      }

      &.open:not(.no-details) {
        background-color: $orange;
        border-bottom: 2px solid $dark-background;
        max-height: 10rem;
        padding: 0.5rem;



        .skill-name {
          .icon:before {
            color: $dark-background;
          }
        }
      }

      .skill-header {
        display: flex;
        height: 2rem;
        justify-content: space-between;
        align-items: center;

        .skill-name {
          display: flex;
          font-size: 14pt;
          flex: 0 1 40%;

          .skill-icon {
            font-size: 16pt;
            margin-right: 10px;
          }
        }

        .skill-rating-container {
          display: flex;
          height: 1.5rem;
          background-color: $dark-background;
          border: 2px solid $dark-background;
          border-radius: 4px;
          flex: 0 1 60%;

          .skill-rating {
            background-color: $green;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 100%;
            border-radius: 4px;
            animation: expandBar 1s ease-in-out;

            .skill-value {
              font-size: 10pt;
              font-weight: bold;
              margin-right: 1rem;
              color: $dark-background;
            }
          }
        }
      }

      .skill-details {
        padding: 1rem 0 0.5rem;
      }
    }
  }

  &.contact {
    .contact-method {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 2rem 0;

      .method-icon-link {
        text-decoration: none;
        margin-right: 10px;
        flex: 0 1 20%;

        .icon {
          font-size: 4rem;
        }
      }

      .method-wrapper {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        flex: 0 1 80%;

        .method-name {
          font-weight: bold;
          font-size: 16pt;
          width: 100%;
          border-bottom: 1px solid $dark-background;
        }

        .method-url {
          width: 100%;
          color: $dark-background;
          font-weight: bold;
          margin-top: 1rem;
          font-size: 12pt;

          &:visited {
            color: $dark-background;
          }

          &:hover {
            color: $orange;
          }

          &:active {
            color: $yellow;
          }
        }
      }
    }
  }
}

.portfolio-container {

  .portfolio-item {
    display: flex;

    .portfolio-details {
      display: flex;
      flex-direction: column;
    }
  }

}

.footer {
  width: 100%;
  min-height: 2rem;
  background-color: $dark-background;
  color: $off-white;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  .footer-wrapper {
    max-width: 640px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > * {
      margin: 1rem;
    }
  }

  .footer-email {
    color: $off-white;
  }
}

@media only screen and (min-width: 360px) {
  header .header-wrapper .page-title-container {
    .page-subtitle {
      display: block;
    }
  }
}

@keyframes expandBar {
  0%{
    width: 10%;}
}