body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'icomoon';
  src: url(/static/media/icomoon.f87b0fe7.eot);
  src: url(/static/media/icomoon.f87b0fe7.eot#iefix) format("embedded-opentype"), url(/static/media/icomoon.73e5d849.ttf) format("truetype"), url(/static/media/icomoon.1f0520ef.woff) format("woff"), url(/static/media/icomoon.d6b0f45f.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal; }

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-envelope:before {
  content: "\E900";
  color: #2B2B2B; }

.icon-mail:before {
  content: "\E900"; }

.icon-email:before {
  content: "\E900"; }

.icon-contact:before {
  content: "\E900"; }

.icon-finder:before {
  content: "\E901"; }

.icon-brand:before {
  content: "\E901"; }

.icon-mac:before {
  content: "\E901";
  color: #1da1f2; }

.icon-os:before {
  content: "\E901"; }

.icon-embed:before {
  content: "\E902"; }

.icon-code:before {
  content: "\E902"; }

.icon-html:before {
  content: "\E902"; }

.icon-xml:before {
  content: "\E902"; }

.icon-react:before {
  content: "\E913";
  color: #61dafb; }

.icon-node-dot-js:before {
  content: "\E903";
  color: #393; }

.icon-android:before {
  content: "\E904";
  color: #138619; }

.icon-windows:before {
  content: "\E905";
  color: #0078d6; }

.icon-ubuntu:before {
  content: "\E906";
  color: #e95420; }

.icon-archlinux:before {
  content: "\E907";
  color: #1793d1; }

.icon-linux:before {
  content: "\E908";
  color: #fcc624; }

.icon-jquery:before {
  content: "\E909";
  color: #0769ad; }

.icon-linkedin:before {
  content: "\E90A";
  color: #0077b5; }

.icon-twitter:before {
  content: "\E90B";
  color: #1da1f2; }

.icon-php:before {
  content: "\E90C";
  color: #777bb4; }

.icon-html5:before {
  content: "\E90D";
  color: #e34f26; }

.icon-css3:before {
  content: "\E90E";
  color: #1572b6; }

.icon-magento:before {
  content: "\E90F";
  color: #ee672f; }

.icon-sass:before {
  content: "\E910";
  color: #c69; }

.icon-javascript:before {
  content: "\E911";
  color: #f7df1e; }

.icon-github:before {
  content: "\E912";
  color: #2B2B2B; }

.icon-git:before {
  content: "\E914";
  color: #f05032; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

body {
  background-color: #bababa;
  color: #2B2B2B; }

#root {
  overflow-y: hidden; }

.page-wrapper {
  position: relative; }

.switch-wrapper {
  position: relative;
  height: 100vh;
  max-height: calc(100vh - 4rem);
  overflow-y: scroll; }
  .switch-wrapper > div {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 4rem); }

header {
  background-color: #2B2B2B;
  height: 4rem; }
  header .header-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    color: #cb7832;
    max-width: 640px;
    margin: auto;
    height: 100%; }
    header .header-wrapper .page-title-container {
      padding-left: 1rem; }
      header .header-wrapper .page-title-container .page-title {
        font-size: 1.2rem; }
      header .header-wrapper .page-title-container .page-subtitle {
        display: none;
        font-size: 0.8rem;
        color: #bababa; }
    header .header-wrapper .nav-main {
      display: -webkit-flex;
      display: flex;
      text-align: center;
      padding-right: 1rem; }
      header .header-wrapper .nav-main .nav-link {
        width: 5rem;
        padding: 1rem 0;
        text-decoration: none;
        color: #cb7832; }
        header .header-wrapper .nav-main .nav-link:visited {
          color: #cb7832; }
        header .header-wrapper .nav-main .nav-link:hover {
          color: #e0c46c;
          border-bottom: 2px solid #e0c46c; }
        header .header-wrapper .nav-main .nav-link:active {
          color: #cbac73;
          border-bottom: 2px solid #cbac73; }
        header .header-wrapper .nav-main .nav-link.active {
          color: #bababa;
          border-bottom: 2px solid #bababa; }

main {
  max-width: 640px;
  margin: auto;
  padding: 1rem 1rem 4rem 1rem; }
  main section:first-of-type .section-title {
    margin: 0 0 1rem 0; }
  main section .section-title {
    border-bottom: 2px solid #2B2B2B;
    margin: 1rem 0; }
  main section .inline-link {
    color: #783c13;
    font-weight: bold; }
    main section .inline-link:visited {
      color: #783c13; }
    main section .inline-link:hover {
      color: #cb7832; }
  main section .inline-contact-link {
    color: #783c13;
    font-weight: bold; }
    main section .inline-contact-link:visited {
      color: #783c13; }
    main section .inline-contact-link:hover {
      color: #cb7832; }
  main section .subtitle {
    margin-bottom: 1rem;
    border-bottom: 1px solid #2B2B2B; }
  main .skills-breakdown .skills-container {
    margin: 20px 0; }
  main .skills-breakdown .skill {
    overflow: hidden;
    max-height: 2rem;
    min-height: 2rem;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    cursor: pointer; }
    main .skills-breakdown .skill:hover {
      background-color: #e0c46c; }
    main .skills-breakdown .skill.open:not(.no-details) {
      background-color: #cb7832;
      border-bottom: 2px solid #2B2B2B;
      max-height: 10rem;
      padding: 0.5rem; }
      main .skills-breakdown .skill.open:not(.no-details) .skill-name .icon:before {
        color: #2B2B2B; }
    main .skills-breakdown .skill .skill-header {
      display: -webkit-flex;
      display: flex;
      height: 2rem;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center; }
      main .skills-breakdown .skill .skill-header .skill-name {
        display: -webkit-flex;
        display: flex;
        font-size: 14pt;
        -webkit-flex: 0 1 40%;
                flex: 0 1 40%; }
        main .skills-breakdown .skill .skill-header .skill-name .skill-icon {
          font-size: 16pt;
          margin-right: 10px; }
      main .skills-breakdown .skill .skill-header .skill-rating-container {
        display: -webkit-flex;
        display: flex;
        height: 1.5rem;
        background-color: #2B2B2B;
        border: 2px solid #2B2B2B;
        border-radius: 4px;
        -webkit-flex: 0 1 60%;
                flex: 0 1 60%; }
        main .skills-breakdown .skill .skill-header .skill-rating-container .skill-rating {
          background-color: #a6e22e;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
                  align-items: center;
          -webkit-justify-content: flex-end;
                  justify-content: flex-end;
          height: 100%;
          border-radius: 4px;
          -webkit-animation: expandBar 1s ease-in-out;
                  animation: expandBar 1s ease-in-out; }
          main .skills-breakdown .skill .skill-header .skill-rating-container .skill-rating .skill-value {
            font-size: 10pt;
            font-weight: bold;
            margin-right: 1rem;
            color: #2B2B2B; }
    main .skills-breakdown .skill .skill-details {
      padding: 1rem 0 0.5rem; }
  main.contact .contact-method {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    margin: 2rem 0; }
    main.contact .contact-method .method-icon-link {
      text-decoration: none;
      margin-right: 10px;
      -webkit-flex: 0 1 20%;
              flex: 0 1 20%; }
      main.contact .contact-method .method-icon-link .icon {
        font-size: 4rem; }
    main.contact .contact-method .method-wrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: flex-end;
              align-items: flex-end;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-flex: 0 1 80%;
              flex: 0 1 80%; }
      main.contact .contact-method .method-wrapper .method-name {
        font-weight: bold;
        font-size: 16pt;
        width: 100%;
        border-bottom: 1px solid #2B2B2B; }
      main.contact .contact-method .method-wrapper .method-url {
        width: 100%;
        color: #2B2B2B;
        font-weight: bold;
        margin-top: 1rem;
        font-size: 12pt; }
        main.contact .contact-method .method-wrapper .method-url:visited {
          color: #2B2B2B; }
        main.contact .contact-method .method-wrapper .method-url:hover {
          color: #cb7832; }
        main.contact .contact-method .method-wrapper .method-url:active {
          color: #e0c46c; }

.portfolio-container .portfolio-item {
  display: -webkit-flex;
  display: flex; }
  .portfolio-container .portfolio-item .portfolio-details {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }

.footer {
  width: 100%;
  min-height: 2rem;
  background-color: #2B2B2B;
  color: #bababa;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0; }
  .footer .footer-wrapper {
    max-width: 640px;
    margin: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .footer .footer-wrapper > * {
      margin: 1rem; }
  .footer .footer-email {
    color: #bababa; }

@media only screen and (min-width: 360px) {
  header .header-wrapper .page-title-container .page-subtitle {
    display: block; } }

@-webkit-keyframes expandBar {
  0% {
    width: 10%; } }

@keyframes expandBar {
  0% {
    width: 10%; } }

