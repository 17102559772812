@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?4jo5v0');
  src:  url('../fonts/icomoon.eot?4jo5v0#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?4jo5v0') format('truetype'),
    url('../fonts/icomoon.woff?4jo5v0') format('woff'),
    url('../fonts/icomoon.svg?4jo5v0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-envelope:before {
  content: "\e900";
  color: #2B2B2B;
}
.icon-mail:before {
  content: "\e900";
}
.icon-email:before {
  content: "\e900";
}
.icon-contact:before {
  content: "\e900";
}
.icon-finder:before {
  content: "\e901";
}
.icon-brand:before {
  content: "\e901";
}
.icon-mac:before {
  content: "\e901";
  color: #1da1f2;
}
.icon-os:before {
  content: "\e901";
}
.icon-embed:before {
  content: "\e902";
}
.icon-code:before {
  content: "\e902";
}
.icon-html:before {
  content: "\e902";
}
.icon-xml:before {
  content: "\e902";
}
.icon-react:before {
  content: "\e913";
  color: #61dafb;
}
.icon-node-dot-js:before {
  content: "\e903";
  color: #393;
}
.icon-android:before {
  content: "\e904";
  color: #138619;
}
.icon-windows:before {
  content: "\e905";
  color: #0078d6;
}
.icon-ubuntu:before {
  content: "\e906";
  color: #e95420;
}
.icon-archlinux:before {
  content: "\e907";
  color: #1793d1;
}
.icon-linux:before {
  content: "\e908";
  color: #fcc624;
}
.icon-jquery:before {
  content: "\e909";
  color: #0769ad;
}
.icon-linkedin:before {
  content: "\e90a";
  color: #0077b5;
}
.icon-twitter:before {
  content: "\e90b";
  color: #1da1f2;
}
.icon-php:before {
  content: "\e90c";
  color: #777bb4;
}
.icon-html5:before {
  content: "\e90d";
  color: #e34f26;
}
.icon-css3:before {
  content: "\e90e";
  color: #1572b6;
}
.icon-magento:before {
  content: "\e90f";
  color: #ee672f;
}
.icon-sass:before {
  content: "\e910";
  color: #c69;
}
.icon-javascript:before {
  content: "\e911";
  color: #f7df1e;
}
.icon-github:before {
  content: "\e912";
  color: #2B2B2B;
}
.icon-git:before {
  content: "\e914";
  color: #f05032;
}